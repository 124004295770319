
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({
    default: () => {
    }
  }) ! data

  open () {
    if (window.innerWidth < 992) {
      window.open(this.data.target, '_blank')
    }
  }
}
