
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import ItemContainer from './items/ItemContainer.vue'
import EmailConfirm from './items/EmailConfirm.vue'
import Logout from './items/Logout.vue'

@Component({
  fetchOnServer: false,
  components: {
    EmailConfirm,
    ItemContainer,
    Logout
  }
})
export default class extends Vue {
  @Prop() webPushId!: string
  @Prop({
    type: Object,
    default () {
      return {}
    }
  }) options!: any

  showPopup: boolean = false
  showNotify: boolean = false

  async fetch () {
    this.onRouteChange()
    await this.$store.dispatch('requisites/getList')
  }

  get menuOptions () {
    return Object.assign({
      history: () => {
        this.$router.push('/payment-history')
        setTimeout(() => {
          this.showPopup = false
        }, 200)
      },
      profile: () => {
        this.$router.push('/profile')
        setTimeout(() => {
          this.showPopup = false
        }, 200)
      },
      webPush: () => {
        this.$eventBus2.$emit('WebPushPermit')
        setTimeout(() => {
          this.showPopup = false
        }, 200)
      },
      autoPayment: false,
      savedCards: () => {
        this.$router.push('/saved-cards')
        setTimeout(() => {
          this.showPopup = false
        }, 200)
      }
    }, this.options)
  }

  @Watch('showPopup')
  onPopupChange (value: boolean) {
    this.$emit('opened', value)
  }

  @Watch('$route', { deep: true })
  onRouteChange () {
    this.showNotify = !(this.$route.path.startsWith('/search/') || this.$route.name === 'payment' || this.$route.name === 'payment-fail' || this.$route.name === 'gibdd-incoming')
    this.switchFavicon()
  }

  mounted () {
    document.body.onmousedown = () => {
      this.showPopup = false
    }

    this.$eventBus.$on('userConfirm', () => {
      this.$store.dispatch('requisites/getList')
      this.$store.dispatch('docs/getList')
    })

    this.$eventBus.$on('showPopup', (val) => {
      this.showPopup = val.isShow
    })

    setTimeout(this.switchFavicon, 5000)
  }

  @Watch('showPopup')
  toggleScroll () {
    if (this.showPopup && window.innerWidth < 575) {
      // @ts-ignore
      document.querySelector('body').style.overflow = 'hidden'
      // @ts-ignore
      document.querySelector('body').style.position = 'fixed'
      // @ts-ignore
      document.querySelector('body').style.width = '100%'
    } else {
      // @ts-ignore
      document.querySelector('body').style.overflow = 'auto'
      // @ts-ignore
      document.querySelector('body').style.position = 'static'
      // @ts-ignore
      document.querySelector('body').style.width = '100%'
    }
  }

  showPopupClick () {
    this.showPopup = !this.showPopup
    this.toggleScroll()
  }

  switchFavicon () {
    let n = 0
    const favicon: HTMLLinkElement = document.querySelector("link[rel~='icon']") as HTMLLinkElement

    const interval = setInterval(() => {
      n = (n + 1) % 2

      const count = this.$store.getters['requisites/getCount']

      if (!this.showNotify) {
        return
      }

      if (count === 0) {
        clearInterval(interval)
        return
      }

      if (n === 0) {
        favicon.href = '/favicon/favicon.ico'
      } else if (count < 10) {
        favicon.href = '/favicon/favicon' + count + '.svg'
      } else {
        favicon.href = '/favicon/faviconN.svg'
      }
    }, 2000)
  }
}
