
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  async logout () {
    try {
      await this.$axios.get(process.env.API_ENDPOINT + 'user/logout').then()
      localStorage.clear()
      if (this.$route.name === 'index') {
        // @ts-ignore
        window.location.reload()
      } else {
        // @ts-ignore
        window.location = '/'
      }
    } catch (err) {
    }
  }
}
